import React, {useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components'




const GalleryImage = (props) => {

const [active, setActive] = useState()
const { bdata} = props

const handleClick = (e, item) => {
    e.preventDefault();

    setActive(item)
    
}

console.log(bdata[0]);

    return (
        <Bhwrap className="container-fluid p-0" style={{backgroundColor:"black"}}>
        {/* <Carousel  showThumbs={false} autoPlay infiniteLoop interval={6000} transitionTime={900} stopOnHover={false}  width="100%"> */}



<div className="row">
    <div className="col-sm-3 scrolldivimg">

    {


bdata.map((item, index)=>{


return  <div key={index} tabindex={index} className="bg-dark ldiv   my-3" style={{backgroundCOlor:"#151515"}}>

<img className="d-block mx-auto " style={{cursor:"pointer"}} onClick={(e)=>handleClick(e, item.src)} src={item.src}  width="100%" />
<h6 className="text-white text-center py-3 m-0 ">{item.title}</h6>
</div>



}) 
}
    </div>
    <div className="col-sm-9 mt-2 mx-0  mainimg">

    <img className="d-block mx-auto "  src={active || bdata[0].src} height="100%" width="100%" />

    </div>

</div>




</Bhwrap>
    );
};


const Bhwrap = styled.div`
overflow:hidden;
background-color:black;

.ldiv{
    transition:0.6s;
            cursor: pointer;
            &:hover{
                transform:translateX(5px);
                
            }

            &:hover h6{

               
                letter-spacing:1px;
                transition:0.2s;
                color:#DEBB0E !important;
            }
            &:focus{

                transform:translateX(5px);
                outline-color:transparent;
             

            }
            &:focus h6{
                letter-spacing:2px;
                transition:0.1s;
                color:#DEBB0E !important;

            }

}
.mainimg{

    height:100%;
    padding:1rem;
}

.scrolldivimg{

    height:90vh;
    overflow-y:scroll;
    overflow-x:hidden;
}
.scrolldivimg::-webkit-scrollbar {
  width: 7px;
}
.scrolldivimg::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.3); 
  border-radius:25px;
  opacity:0.5;
}


@media(max-width:960px){

.mainimg{

    display:none;
}
  
}



`
export default GalleryImage;